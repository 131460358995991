import React from "react"
import Layout from "../components/layout"
import KeyFeatures from "../components/pages/Index/KeyFeatures"
import UseCases from "../components/common/UseCases"
import TestimonialsSection from "../components/common/TestimonialsSection"
import MobileAppLinksSection from "../components/common/MobileAppLinksSection"
import HappyCustomerSection from "../components/common/HappyCustomerSection"
import BusinessModelsWithFormSection from "../components/common/BusinessModelsWithFormSection"
import PricingSection from "../components/pages/Index/PricingSection"
import Seo from "../components/seo"
import HeroSection from "../components/pages/Index/HeroSection"
import HeroSectionAfterAnimation from "../components/pages/Index/HeroSectionAfterAnimation"
import HeroSectionAnimationAfterWidget from "../components/pages/Index/HeroSectionAnimationAfterWidget"
import BlogSection from "../components/common/BlogSection"
import {
  blogsSection,
  businessModelsWithFormSection,
  faqs,
  happyCustomerSection,
  heroSection,
  heroSectionAnimationAfterWidget,
  keyFeaturesSection,
  pricingSection,
  testimonialSection,
  useCasesSection,
} from "../Content/Home"
import FaqsSection from "../components/common/FaqsSection"
import { mobileAppLinksSection } from "../Content/Common"

const IndexPage = () => (
  <Layout>
    <Seo
      title="Seamless Client and Practice Management Software for Accountants"
      description={`Synkli is an all-in-one practice management software for accountants, simplifying digital data collection from clients for efficient accounting practice management.`}
    />

    <HeroSection
      heroTitleFirstRowText={heroSection.heroTitleFirstRowText}
      heroTitleSecondRowText={heroSection.heroTitleSecondRowText}
      heroTitleThirdRowText={heroSection.heroTitleThirdRowText}
      description={heroSection.description}
      brushImage={heroSection.brushImage}
      btns={heroSection.btns}
    />

    <HeroSectionAfterAnimation />

    <HeroSectionAnimationAfterWidget
      image={heroSectionAnimationAfterWidget.image}
    />

    {/* <PricingSection
      tagLine={pricingSection.tagLine}
      title={pricingSection.title}
      description={pricingSection.description}
      percentageOffText={pricingSection.percentageOffText}
      pricingArrowIcon={pricingSection.pricingArrowIcon}
      plans={pricingSection.plans}
    /> */}

    <KeyFeatures
      subTitle={keyFeaturesSection.subTitle}
      title={keyFeaturesSection.title}
      tabs={keyFeaturesSection.tabs}
    />

    <UseCases
      subTitle={useCasesSection.subTitle}
      title={useCasesSection.title}
      useCases={useCasesSection.useCases}
    />

    <TestimonialsSection
      title={testimonialSection.title}
      description={testimonialSection.description}
    />

    <MobileAppLinksSection
      title={mobileAppLinksSection.title}
      description={mobileAppLinksSection.description}
    />

    <HappyCustomerSection
      subTitle={happyCustomerSection.subTitle}
      mainTitle={happyCustomerSection.mainTitle}
      title={happyCustomerSection.title}
      description={happyCustomerSection.description}
    />

    <FaqsSection faqs={faqs} />

    <div className="pb-16 md:pb-24">
      <BusinessModelsWithFormSection
        title={businessModelsWithFormSection.title}
        description={businessModelsWithFormSection.description}
        formSubmitButtonText={
          businessModelsWithFormSection.formSubmitButtonText
        }
      />
    </div>

    <BlogSection subTitle={blogsSection.subTitle} title={blogsSection.title} />
  </Layout>
)

export default IndexPage
